import * as React from 'react'

import { Layout } from '../components/Layout'
import { button } from '../components/Button.module.scss'
import * as styles from './Error.module.scss'

const NotFoundPage = () => {
  const [redirect, setRedirect] = React.useState<string | undefined>("/")
  const [goBack, setGoBack] = React.useState(false)
  React.useEffect(() => {
    if (document.referrer) {
      if (new URL(document.referrer).host === window.location.host) {
        setRedirect(undefined);
        setGoBack(true);
      }
    }
  })
  const back = () => {
    if (goBack) {
      window.history.back()
    }
  }
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <h1>Page not found</h1>
          <div>
            The link you followed may be broken, or the page may have been
            removed
          </div>
          <a href={redirect} className={button} onClick={back}>
            Go back
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
