// extracted by mini-css-extract-plugin
export var container = "Error-module--container--cOrua";
export var contentContainer = "Error-module--content-container--8te0d";
export var fourtynineB = "Error-module--fourtynine-b--uDMob";
export var inputLabel = "Error-module--input-label--Mpo30";
export var seventysixB = "Error-module--seventysix-b--3Ep39";
export var sixteenB = "Error-module--sixteen-b--7QTld";
export var sixteenR = "Error-module--sixteen-r--bzq4Z";
export var thirteenMK = "Error-module--thirteen-m-k--5ajta";
export var thirtynineB = "Error-module--thirtynine-b--k+gvx";
export var thirtyoneB = "Error-module--thirtyone-b--+OOsW";
export var twentyB = "Error-module--twenty-b--fGeoI";
export var twentyR = "Error-module--twenty-r--785OI";
export var twentyfiveB = "Error-module--twentyfive-b--L8HJ0";
export var wrapper = "Error-module--wrapper--zmQjd";